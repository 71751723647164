
  import { Options, Vue } from 'vue-class-component';
  import cookies from 'cookies-js'
  import SubjectList from '../components/subjects/Subjects.vue'
  import EAList from '../components/exams-assignments/EAs.vue'
  import server from '../server'
  import calender from '../components/todo-calender/Calender.vue'
  import todo from '../components/todo-calender/Todo.vue'
  function timestampToDatetimeInputString(timestamp: number) {
    const date = new Date((timestamp + new Date().getTimezoneOffset() * -60 * 1000));
    return date.toISOString().slice(0, 19);
  }

  @Options({
    data(){
      return{
        datevalue:timestampToDatetimeInputString(Date.now()),
        subjects:[],
        create_class:"Select subject",
        create_exam_study:"",
        create_weight:"",
        create_description:"",
        create_title:"",
        assignments:[],
        exams:[],
        loaded:false,
        animation:{
          doing:false,
          fade:false,
        },
        tutorial:{
          doing:false,
          page:0,
          comein:false,
          contentcomein:false,
          doingleave:false,
          content:[
            {
              image:"https://i.imgur.com/tvXGYFW.png",
              title:"Creating Assignments and Exams",
              text:`Creating assignments and exams is easy! If you want to change the type of item, click on “Assignment” and a dropdown box will let you switch to an exam. Then, click on the “Select subject” box and it will have a list of all the subjects that you’ve created. Click on the one you want to create the assignment/exam for. Next, click on the box that shows the current date and time. You don’t need to worry about the time, but select the date of the assignment/exam. If you’re creating an exam, enter the number of days you want to study before the exam, in the “Study days” box. Then, when you’re viewing the exam on your calendar, it will show you the days you should study. Next, go to the right side (scroll down on mobile). Fill out the assignment/exam name and description. Once you’ve done all of the above, click on “Create assignment”, and wait. Once the page reloads, you should see the assignment/exam.`
            },{
              image:"https://i.imgur.com/fKW34sC.png",
              title:"Subjects",
              text:`Deleting, editing, and creating your subjects is fun, simple, and easy. If you want to create a subject, just click on the plus box & then fill out a name and description for the subject in the “Name”, and “Description” box. Then, click on the “💾” button and wait until the page reloads. Then, your subject will be created. If you want to edit your subject, click on the “✏️” button. The box will have your subject’s name at the top, and your subject’s description at the bottom. You can edit them, and when you’re happy click on the “💾” button and wait until the page reloads. If you want to delete your subject, click on the “🗑” button. To confirm, click on the “Yes, delete it” button. If not, click on the “No, nevermind button”. Remember that if you delete a subject, it will also delete all of the exams and assignments linked to it, forever.`
            },{
              image:"https://i.imgur.com/WzrVYKX.png",
              title:"Assignments and Exams",
              text:`Viewing, deleting, and editing your assignments and exams may seem difficult at first, but once you get the hang of it, you’ll be ready to use the more advanced features of the website. When viewing your assignments and exams, you will see 4 or 5 buttons. The “📖” button is unique to exams, and is the main way to tell exams and assignments apart. It will show the number of days you want to study before your exam. The “🏋️” button shows the weight of the assignment/exam on your final grade. The “🗓” button shows the due date/date of your assignment/exam. The pencil button allows you to edit your exam/assignment. After you click on it, you can edit the name, date, weight, study days (exams only), and description. Put the “💾” button to save it, and wait for the page to reload. Push the “✅” button if you completed the exam/assignment. If you did, click on the “Yes, finish it” button, and if not, click on the “No, nevermind”. One thing to remember is that exams will always be listed before assignments.`
            },{
              image:"https://i.imgur.com/AjRxeWw.png",
              title:"Calendar and to-dos",
              text:`When viewing your calendar, you may notice the month and year in the top left hand corner, and a set of arrows in the top right hand corner. The first “←” button will take you back 1 year on the calendar, and the second will take you back 1 month. The first “→” button will take you ahead 1 month, while the second one will take you ahead 1 year. The todo widget will list out all of your subjects, with your assignments and exams below. One thing to remember is that exams will always be listed before assignments. Also, if you click on the assignment/exam it will take you to the page for it.`
            },{
              image:"https://i.imgur.com/osY8BlR.png",
              title:"Stand-alone Assignments and Exams",
              text:`When viewing assignments and exams, you can see the assignment/exam description, date, study before days (exams only), and weight on the left hand side, and delete the assignment/exam using the “🗑” and edit it using the “✏️” button. On the left hand side, you can see the calendar displaying the assignment/exam date on it. Click on the “←←←” button to go back to the subject page.`
            },{
              image:"https://i.imgur.com/vaPhF6u.png",
              title:"Stand-alone Subjects",
              text:`When viewing a subject, on the left will be the subject description and on the right will be all of the exams and assignments related to that subject. You can click on the “🗑” button to delete the subject, and click on the “✏️” button to edit the subject. Click on the “←←” button to go back to the subjects page.`
            }
          ]
        },
        create_type:"Assignment"
      }
    },
    async mounted(){
      if(!cookies.get('userid')){
        this.$router.push("/signup/")
      }
      if(Object.keys(this.$route.query).includes("fade")){
        this.animation.doing=true;
        setTimeout(()=>{this.animation.fade=true},100)
      }
      if(Object.keys(this.$route.query).includes("first")){
        this.tutorial.doing=true
        setTimeout(()=>{this.tutorial.comein=true},100)
        setTimeout(()=>{this.tutorial.contentcomein=true},1100)
      }
      let subjects = await fetch(`${server}/${cookies.get("userid")}/subjects/?passcode=${cookies.get("passcode")}`)
      this.subjects = await subjects.json()
      let ea = await fetch(`${server}/${cookies.get("userid")}/assignments-exams/?passcode=${cookies.get("passcode")}`)
      let ea_list:any = await ea.json()
      this.assignments = (ea_list.assignments)
      this.exams = (ea_list.exams)
      this.loaded=true
      this.$forceUpdate()
    },
    methods:{
      tutorial_nav(by: number){
        if(this.tutorial.page+by<0){
          this.tutorial_leave('')
        }else if(this.tutorial.page+by>=this.tutorial.content.length){
          this.tutorial_leave('')
        }else{
          this.tutorial.contentcomein=false
          setTimeout(()=>{
            this.tutorial.page+=by
            this.tutorial.contentcomein=true
          },500)
        }
      },
      tutorial_leave(path:string){
        setTimeout(()=>{this.tutorial.contentcomein=false},100)
        setTimeout(()=>{this.tutorial.comein=false;this.tutorial.doingleave=true},1100)
        setTimeout(()=>{this.tutorial.doing=false},2100)
        setTimeout(()=>{this.$router.push("/"+path)},3100)
      },
      async cre(){
        let data = {
          name:this.create_title,
          description:this.create_description+"$weight>"+this.create_weight,
          subject:this.create_class.id,
          due:this.datevalue,
          study:this.create_exam_study,
          passcode:cookies.get("passcode"),
        }
        console.log(data)
        let res = await fetch(`${server}/${cookies.get("userid")}/${this.create_type.toLowerCase()}s/`,{
          method:"POST",
          body:JSON.stringify(data),
          headers:{
            "Content-Type":"application/json"
          }
        })
        if(res.ok){
          location.reload()
        }else{
          alert("Something went wrong, please don't ever tell me")
        }
      },
      stringify(arr:any){try{return JSON.stringify(arr)}catch(e){return "[]"}}
    },
    components:{'subject-list':SubjectList,'ea-list':EAList,calender,todo}
  })
  export default class Home extends Vue {}
