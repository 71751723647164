
  import { Options, Vue } from 'vue-class-component';
  import calanderItem from './CalenderItem.vue'

  @Options({
    data(){return{
        exams:[],
        assignments:[],
        dates:[],
        month:0,
        monthname:"",
        year:0,
        whiteroom:[],
    }},
    mounted(){
        this.exams=this._.attrs.exams;
        this.assignments=this._.attrs.assignments;
        var now = new Date();
        this.year=now.getFullYear()
        this.month=now.getMonth()
        setTimeout(()=>{this.calender()},250)
    },
    methods: {
        calender(){
            this.monthname=new Date(this.year,this.month,1).toLocaleString('default', { month: 'long' })
            let i=0;for(i = 0; i < new Date(this.year, this.month+1, 0).getDate(); i++){
                this.dates.push([])
            }
            this._.attrs.exams.forEach((i:any)=>{
                let date=new Date(i.date)
                let index=date.getDate()-1
                this.trypush(date,index,i)
                let interval=0
                Array.from({length: i.studybefore}, ()=>{
                  interval++
                  let iclone=JSON.parse(JSON.stringify(i))
                  iclone.name='Study: '+iclone.name
                  let clonedate=new Date(iclone.date)
                  clonedate.setDate(clonedate.getDate()-interval)
                  console.log(clonedate)
                  this.trypush(clonedate,clonedate.getDate()-1,iclone)
                })
            })
            this._.attrs.assignments.forEach((i:any)=>{
                let date=new Date(i.duedate)
                let index=date.getDate()-1
                if(date.getMonth()==this.month&&this.year==date.getFullYear()){this.dates[index].push(i)}
            })
            i=0;for(i=0;i<new Date(this.year, this.month, 1).getDay();i++){
                this.whiteroom.push([])
            }
        },
        trypush(date:any,index:number,i:any){
          if(date.getMonth()==this.month&&this.year==date.getFullYear()){this.dates[index].push(i)}
        },
        cyear(i:number){
            this.year+=i
            this.dates=[]
            this.whiteroom=[]
            this.calender()
        },
        cmonth(i:number){
            console.log(i)
            this.month+=i
            if(this.month<0) this.month=11;
            if(this.month>11) this.month=0;
            this.monthname=new Date(this.year, this.month, 1).toLocaleString('default', { month: 'long' })
            this.dates=[]
            this.whiteroom=[]
            this.calender()
        },
    },
    components:{
        'calender-item':calanderItem
    }
  })
  export default class Calendar extends Vue {}
