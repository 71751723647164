import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21ea09de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "item-main",
    style: _normalizeStyle({'background-color':_ctx.number%2==0?'#eee':'white'})
  }, [
    _createElementVNode("b", _hoisted_1, _toDisplayString(_ctx.number), 1),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "item",
          key: index
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("div", {
              class: "left",
              style: _normalizeStyle({'background-color':item.color_side})
            }, null, 4),
            _createElementVNode("div", {
              class: "right",
              style: _normalizeStyle({'background-color':item.color_pastel})
            }, [
              _createElementVNode("a", {
                href: '#/'+item.id+(item.duedate?'/assignment/':'/exam/'),
                style: {"color":"black","text-decoration":"none"}
              }, [
                _createElementVNode("b", null, _toDisplayString(item.name), 1)
              ], 8, _hoisted_2)
            ], 4)
          ])
        ]))
      }), 128))
    ])
  ], 4))
}