
  import { Options, Vue } from 'vue-class-component';
  import TodoSubject from './TodoSubject.vue'

  @Options({
    data(){return{
      subjects:[],
      assignments:[],
      exams:[],
      sorted:[],
    }},
    mounted(){
      setTimeout(() => {
        this.init()
      },250)
    },
    components:{
      'todo-subject':TodoSubject,
    },
    methods:{
      init(){
        this.subjects=this._.attrs.subjects;
        this.assignments=this._.attrs.assignments;
        this.exams=this._.attrs.exams;
  
        this._.attrs.subjects.forEach((i:any,d:number)=>{
          this.sorted.push({
            subject:i,
            assignments:[],
            exams:[],
          })
          this.assignments.filter((ii:any)=>ii.subject==i.id).forEach((ii:any,dd:number)=>{
            this.sorted[d].assignments.push(ii)
          })
          this.exams.filter((ii:any)=>ii.subject==i.id).forEach((ii:any,dd:number)=>{
            this.sorted[d].exams.push(ii)
          })
        })
      }
    }
  })
  export default class Todo extends Vue {}
