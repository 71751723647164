
  import { Options, Vue } from 'vue-class-component';
  import cookies from 'cookies-js'
  import server from '../../server'
  import calender from '../../components/todo-calender/Calender.vue'
  function timestampToDatetimeInputString(timestamp: number) {
    const date = new Date((timestamp + new Date().getTimezoneOffset() * -60 * 1000));
    return date.toISOString().slice(0, 19);
  }
  function dateTimeInputStringToTimestamp(datetime: any){
    return datetime.split("T")[0]
  }

  @Options({
    data(){return{
      assignment:{name:'Assignment',description:'Description',weight:0,date:new Date().toString()},
      edit:{name:'Assignment',description:'Description',weight:0,date:new Date().toString()},
      mode:'view',
      update:0,
    }},
    async mounted(){
      let subjects = await fetch(`${server}/${cookies.get("userid")}/assignment/${this.$route.params.a}/?passcode=${cookies.get("passcode")}`)
      let data:any = await subjects.json()
      data.weight = data.description.split("$weight>")[data.description.split("$weight>").length-1]-0
      data.description = data.description.split("$weight>").splice(data.description.split("$weight>").length-2,1).join("$weight>")
      this.assignment=data
      this.edit=data
      this.edit.date=dateTimeInputStringToTimestamp(data.duedate)
    },
    methods:{
      async save(){
        if(this.mode=='edit'){
          let subject = await fetch(`${server}/${cookies.get("userid")}/assignments/${this.$route.params.a}`,{
            method:"PUT",
            headers:{
              "Content-Type":"application/json"
            },
            body:JSON.stringify({
              name:this.edit.name,
              description:this.edit.description+"$weight>"+this.edit.weight,
              date:this.edit.date,
              passcode:cookies.get('passcode')
            })
          })
          if(subject.ok){
            this.mode='view'
            this.assignment=this.edit
            this.update++
          }else{
            alert("Error saving subject")
          }
        }else{
          let data=await fetch(`${server}/${cookies.get("userid")}/assignments/${this.$route.params.a}`,{
            method:"DELETE",
            headers:{
              "Content-Type":"application/json"
            },
            body:JSON.stringify({
              passcode:cookies.get('passcode')
            })
          })
          if(data.ok){
            this.$router.push('/'+this.assignment.subject+'/subject/')
          }else{alert("Something went wrong, please don't try again, it's on me.")}
        }
      },
      duedate(date:string){
        let ddate=new Date(date)
        return ddate.getDate() + '/' + (ddate.getMonth() + 1) + '/' +  ddate.getFullYear()
      },
      stringify(obj:any){
        return JSON.stringify(obj)
      }
    },
    components:{calender}
  })
  export default class Subject extends Vue {}
