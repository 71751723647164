import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Index from '../views/Index.vue'
import Login from '../views/account/Login.vue'
import Signup from '../views/account/Signup.vue'
import Calander from '../views/calander/Calander.vue'
import Todo from '../views/calander/Todo.vue'
import Assignment from '../views/items/Assignment.vue'
import Exam from '../views/items/Exam.vue'
import Subject from '../views/items/Subject.vue'
import Assignments from '../views/items/Assignments.vue'
import Exams from '../views/items/Exams.vue'
import Subjects from '../views/items/Subjects.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Index
  },{
    path: '/login/',
    component: Login
  },{
    path: '/signup/',
    component: Signup
  },{
    path: '/calander/',
    component: Calander
  },{
    path: '/:s/subject/',
    component: Subject,
    props:true,
  },{
    path: '/subjects/',
    component: Subjects
  },{
    path: '/:a/assignment/',
    component: Assignment,
    props:true,
  },{
    path: '/assignments/',
    component: Assignments
  },{
    path: '/:e/exam/',
    component: Exam,
    props:true,
  },{
    path: '/exams/',
    component: Exams
  },{
    path: '/todo/',
    component: Todo
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
