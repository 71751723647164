
  import { Options, Vue } from 'vue-class-component';
  import cookies from 'cookies-js'
  import server from '../../server'
  import calender from '../../components/todo-calender/Calender.vue'

  @Options({
    async mounted(){
      let ea = await fetch(`${server}/${cookies.get("userid")}/assignments-exams/?passcode=${cookies.get("passcode")}`)
      let ea_list:any = await ea.json()
      this.assignments = (ea_list.assignments)
      this.exams = (ea_list.exams)
    },
    data(){return{
      assignments:[],
      exams:[],
    }},
    components:{
      calender
    }
  })
  export default class Calander extends Vue {}
