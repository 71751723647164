
  import { Options, Vue } from 'vue-class-component';
  import cookies from 'cookies-js'
  import server from '../../server'
  import eas from '../../components/exams-assignments/EAs.vue'

  @Options({
    data(){return{assignments:[]}},
    async mounted(){
      let ea = await fetch(`${server}/${cookies.get("userid")}/assignments-exams/?passcode=${cookies.get("passcode")}`)
      let ea_list:any = await ea.json()
      this.assignments = (ea_list.assignments)
    },
    components:{eas}
  })
  export default class Subject extends Vue {}
