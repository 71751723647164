import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-614318c1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", null, [
      _createElementVNode("a", {
        href: '#/'+_ctx.item.id+'/subject/'
      }, null, 8, _hoisted_1),
      _createTextVNode(_toDisplayString(_ctx.item.name), 1)
    ]),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exams, (exam) => {
        return (_openBlock(), _createElementBlock("li", {
          key: exam.id
        }, [
          _createElementVNode("a", {
            href: '#/'+exam.id+'/exam/'
          }, _toDisplayString(exam.name), 9, _hoisted_2)
        ]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assignments, (assignment) => {
        return (_openBlock(), _createElementBlock("li", {
          key: assignment.id
        }, [
          _createElementVNode("a", {
            href: '#/'+assignment.id+'/assignment/'
          }, _toDisplayString(assignment.name), 9, _hoisted_3)
        ]))
      }), 128))
    ])
  ]))
}