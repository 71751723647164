
  import { Options, Vue } from 'vue-class-component';
  import server from '../../server'
  import { AccountResponse } from '../../types'
  import cookies from 'cookies-js'

  @Options({
    data(){
      return {
        good_passcode:true,
        bad_username:true,
        tried_passcode:false,
        username:"",
        passcode:"",
        verify_passcode:"",
        creating_account:false,
        bad:false,
        leaving:false,
        come:false,
      }
    },
    methods:{
      check_passcode(){
        this.tried_passcode=true
        if(this.passcode==this.verify_passcode&&this.passcode!=""){
          this.good_passcode=true;
        }else{
          this.good_passcode=false;
        }
        if(this.username==""){this.bad_username=true}
        else{this.bad_username=false}
      },
      async confirm_account(){
        let account=await fetch(`${server}/accounts/`,{
          method:"POST",
          headers:{
            "Content-Type":"application/json"
          },
          body:JSON.stringify({
            username:this.username,
            passcode:this.passcode,
          })
        })
        if(account.ok){
          let userid:AccountResponse=await account.json()
          cookies.set("passcode", this.passcode)
          cookies.set("userid", userid.id)
          this.come=false;
          this.leaving=true;
          setTimeout(()=>{this.$router.push("/?first")},1000)
        }else{
          this.bad=true
        }
      },
      leave(){
        this.come=false;
        this.leaving=true;
        setTimeout(()=>{this.$router.push("/login/")},1000)
      }
    },
    mounted(){
      setTimeout(()=>{this.come=true});
    }
  })
  export default class Signup extends Vue {}
