import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21d88814"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ea-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ea = _resolveComponent("ea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx._.attrs.exams, (item) => {
      return (_openBlock(), _createBlock(_component_ea, {
        class: "ea",
        key: item,
        item: item
      }, null, 8, ["item"]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx._.attrs.assignments, (item) => {
      return (_openBlock(), _createBlock(_component_ea, {
        class: "ea",
        key: item,
        item: item
      }, null, 8, ["item"]))
    }), 128))
  ]))
}