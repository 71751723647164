
  import { Options, Vue } from 'vue-class-component';
  import cookies from 'cookies-js'
  import server from '../../server'
  import todo from '../../components/todo-calender/Todo.vue'

  @Options({
    async mounted(){
      let ea = await fetch(`${server}/${cookies.get("userid")}/assignments-exams/?passcode=${cookies.get("passcode")}`)
      let ea_list:any = await ea.json()
      this.assignments = (ea_list.assignments)
      this.exams = (ea_list.exams)
      let subjects = await fetch(`${server}/${cookies.get("userid")}/subjects/?passcode=${cookies.get("passcode")}`)
      let data:any = await subjects.json()
      this.subjects=data
      console.log(this.subjects,this.exams,this.subjects)
    },
    data(){return{
      subjects:[],
      assignments:[],
      exams:[],
    }},
    components:{
      todo
    }
  })
  export default class Todo extends Vue {}
