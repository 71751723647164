import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cb06102"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section" }
const _hoisted_2 = { class: "sec-title" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "sec-content" }
const _hoisted_5 = { class: "sec-content-description" }
const _hoisted_6 = {
  key: 0,
  class: "subject-description-container"
}
const _hoisted_7 = { class: "subject-description" }
const _hoisted_8 = {
  key: 1,
  class: "subject-description-container"
}
const _hoisted_9 = { class: "subject-controls-container" }
const _hoisted_10 = {
  key: 0,
  class: "subject-control"
}
const _hoisted_11 = {
  key: 1,
  class: "subject-control"
}
const _hoisted_12 = { class: "sec-content-calender" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_calender = _resolveComponent("calender")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, [
      _createElementVNode("a", {
        href: '#/'+_ctx.exam.subject+'/subject/',
        class: "sec-title-back"
      }, "←←←", 8, _hoisted_3),
      _createTextVNode(" " + _toDisplayString(_ctx.exam.name), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          (_ctx.mode!='edit')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("b", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exam.description.split('\n'), (line) => {
                    return (_openBlock(), _createElementBlock("p", { key: line }, _toDisplayString(line), 1))
                  }), 128)),
                  _createElementVNode("p", null, "Date: " + _toDisplayString(_ctx.duedate(_ctx.exam.date)), 1),
                  _createElementVNode("p", null, "Study before: " + _toDisplayString(_ctx.exam.studybefore), 1),
                  _createElementVNode("p", null, "Weight: " + _toDisplayString(_ctx.exam.weight), 1)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _withDirectives(_createElementVNode("input", {
                  class: "subject-title-edit subject-edit",
                  type: "text",
                  placeholder: "Name",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.edit.name) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.edit.name]
                ]),
                _withDirectives(_createElementVNode("input", {
                  class: "subject-title-edit subject-edit",
                  type: "number",
                  placeholder: "Study before",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.edit.studybefore) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.edit.studybefore]
                ]),
                _withDirectives(_createElementVNode("input", {
                  class: "subject-title-edit subject-edit",
                  type: "number",
                  placeholder: "Weight",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.edit.weight) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.edit.weight]
                ]),
                _withDirectives(_createElementVNode("input", {
                  class: "subject-title-edit subject-edit",
                  type: "date",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.edit.date) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.edit.date]
                ]),
                _withDirectives(_createElementVNode("textarea", {
                  class: "subject-description-edit subject-edit",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.edit.description) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.edit.description]
                ])
              ]))
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.mode=='view')
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("button", {
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.mode='delete'))
                }, "🗑"),
                _createElementVNode("button", {
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.mode='edit'))
                }, "✏️")
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("button", {
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.mode='view'))
                }, "❌"),
                _createElementVNode("button", {
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.save()))
                }, "✅")
              ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        (_openBlock(), _createBlock(_component_calender, {
          exams: [_ctx.exam],
          assignments: [],
          key: _ctx.exam
        }, null, 8, ["exams"]))
      ])
    ])
  ]))
}