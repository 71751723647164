
  import { Options, Vue } from 'vue-class-component';
  import cookies from 'cookies-js'
  import server from '../../server'
  import calender from '../../components/todo-calender/Calender.vue'

  @Options({
    data(){return{
      subject:{name:'Subject',description:'Description'},
      assignments:[],
      exams:[],
      mode:'view',
      edit:{
        name:'Subject',
        description:'Description'
      }
    }},
    async mounted(){
      let subjects = await fetch(`${server}/${cookies.get("userid")}/subject/${this.$route.params.s}/?passcode=${cookies.get("passcode")}`)
      let data:any = await subjects.json()
      this.subject=data.subject
      this.edit=data.subject
      this.assignments=data.assignments
      this.exams=data.exams
    },
    methods:{
      async save(){
        if(this.mode=='edit'){
          let subject = await fetch(`${server}/${cookies.get("userid")}/subjects/${this.subject.id}`,{
            method:"PUT",
            headers:{
              "Content-Type":"application/json"
            },
            body:JSON.stringify({
              name:this.edit.name,
              description:this.edit.description,
              passcode:cookies.get('passcode')
            })
          })
          if(subject.ok){
            this.mode='view'
            this.subject=this.edit
          }else{
            alert("Error saving subject")
          }
        }else{
          let data=await fetch(`${server}/${cookies.get("userid")}/subjects/${this.$route.params.s}`,{
            method:"DELETE",
            headers:{
              "Content-Type":"application/json"
            },
            body:JSON.stringify({
              passcode:cookies.get('passcode')
            })
          })
          if(data.ok){
            this.$router.push('/?fade')
          }else{alert("Something went wrong, please don't try again, it's on me.")}
        }
      }
    },
    components:{calender}
  })
  export default class Subject extends Vue {}
