
  import { Options, Vue } from 'vue-class-component';

  @Options({
    data(){return{
      item:{name:'Subject',id:0},
      assignments:[],
      exams:[],
    }},
    mounted(){
      setTimeout(()=>{
        this.item=this._.attrs.item
        this.assignments=this._.attrs.assignments
        this.exams=this._.attrs.exams
        console.log(this.item)
      },250)
    },
  })
  export default class TodoSubject extends Vue {}
