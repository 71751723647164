
  import { Options, Vue } from 'vue-class-component';
  import server from '../../server/'
  import cookies from 'cookies-js'
  function timestampToDatetimeInputString(timestamp: number) {
    const date = new Date((timestamp + new Date().getTimezoneOffset() * -60 * 1000));
    return date.toISOString().slice(0, 19);
  }
  function dateTimeInputStringToTimestamp(datetime: any){
    return datetime.split("T")[0]
  }

  @Options({
    props: ['item'],
    data(){
        return{
            mode:'view',
            item_name:"",
            item_description:"",
            item_read_description:"",
            item_date:"",
            item_study:0,
            item_weight:0,
            item_read_weight:0,
            type:'assignment'
        }
    },
    mounted(){
      setTimeout(()=>{
        this.type=!!this.item.studybefore?'exam':'assignment';
        this.item_weight=this.item.description.split("$weight>").splice(-1)-0
        this.item_read_weight=this.item_weight
        this.item_read_description=this.item.description.split("$weight>").splice(0,1).join("$weight>")
        this.item_name=this.item.name;
        this.item_description=this.item_read_description;
        this.item_date=dateTimeInputStringToTimestamp(this.type=='exam'?this.item.date:this.item.duedate);
        if(this.type=="exam"){this.item_study=this.item.studybefore}
      })
    },
    methods:{
      async del(){
        let subject = await fetch(`${server}/${cookies.get("userid")}/${this.type}s/${this.item.id}`,{
          method:"DELETE",
          headers:{
            "Content-Type":"application/json"
          },
          body:JSON.stringify({
            passcode:cookies.get('passcode')
          })
        })
        if(subject.ok){
          location.reload()
        }else{
          alert("Error deleting subject")
        }
      },
      async edt(){
        let subject = await fetch(`${server}/${cookies.get("userid")}/${this.type}s/${this.item.id}`,{
          method:"PUT",
          headers:{
            "Content-Type":"application/json"
          },
          body:JSON.stringify({
            name:this.item_name,
            description:this.item_description+"$weight>"+this.item_weight,
            passcode:cookies.get('passcode'),
            date:(this.item_date),
            study:this.type=='exam'?this.item_study:0,
          })
        })
        if(subject.ok){
          location.reload()
        }else{
          alert("Error editing subject")
        }
      },
      duedate(date:string){
        let ddate=new Date(date)
        return ddate.getDate() + '/' + (ddate.getMonth() + 1) + '/' +  ddate.getFullYear()
      }
    }
  })
  export default class SubjectCard extends Vue {}
