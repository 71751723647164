
  import { Options, Vue } from 'vue-class-component';

  @Options({
    data(){return{
        items:[],
        number:0,
    }},
    mounted(){
      let items=this._.attrs.items;
      items.forEach((i:any,d:number)=>{
        let colour=~~(360 * Math.random())
        i.color_pastel=`hsla(${colour},70%,70%,0.8)`
        i.color_side=`hsl(${colour},70%,70%)`
        items[d]=i
      })
      this.items=items
      this.number=this._.attrs.number
    },
  })
  export default class CalendarItem extends Vue {}
