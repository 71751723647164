
  import { Options, Vue } from 'vue-class-component';
  import SubjectCard from './SubjectCard.vue'
  import server from '../../server/'
  import cookies from 'cookies-js'

  @Options({
    props:["subjects"],
    components:{"subject-card":SubjectCard},
    data(){
      return{
        creating:false,
        create_name:"",
        create_description:"",
        creating_subject:false,
      }
    },
    methods:{
      async create(){
        this.creating_subject=true
        let subject = await fetch(`${server}/${cookies.get('userid')}/subjects/`,{
          method:"POST",
          headers:{
            "Content-Type":"application/json"
          },
          body:JSON.stringify({
            name:this.create_name,
            description:this.create_description,
            passcode:cookies.get('passcode')
          })
        })
        if(subject.ok){
          let subjectid: any = await subject.json()
          this.$router.push(`/${subjectid.id}/subject/`)
        }else{
          alert("Error creating subject")
        }
        this.creating_subject=false
      }
    }
  })
  export default class Subjects extends Vue {}
