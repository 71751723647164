
  import { Options, Vue } from 'vue-class-component';
  import cookies from 'cookies-js'
  import server from '../../server'
  import subjects from '../../components/subjects/Subjects.vue'

  @Options({
    data(){return{subjects:[]}},
    async mounted(){
      let subjects = await fetch(`${server}/${cookies.get("userid")}/subjects/?passcode=${cookies.get("passcode")}`)
      this.subjects = await subjects.json()
    },
    components:{subjects}
  })
  export default class Subject extends Vue {}
