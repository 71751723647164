import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d685426"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-todo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_todo_subject = _resolveComponent("todo-subject")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sorted, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "item-todo",
        key: item.id
      }, [
        _createVNode(_component_todo_subject, {
          item: item.subject,
          assignments: item.assignments,
          exams: item.exams
        }, null, 8, ["item", "assignments", "exams"])
      ]))
    }), 128))
  ]))
}