
  import { Options, Vue } from 'vue-class-component';
  import server from '../../server'
  import { AccountResponse } from '../../types/'
  import cookies from 'cookies-js'

  @Options({
    data(){
      return {
        username:"",
        passcode:"",
        getting_account:false,
        bad_username:false,
        bad_passcode:false,
        leaving:false,
        come:false,
      }
    },
    methods:{
      async confirm_account(){
        let account = await fetch(`${server}/accounts/?username=${this.username}&passcode=${this.passcode}`)
        if(account.ok){
          let userid:AccountResponse=await account.json()
          cookies.set("userid", userid.id)
          cookies.set("passcode", this.passcode)
          this.come=false;
          this.leaving=true;
          setTimeout(()=>{this.$router.push("/?fade")},1000)
        }else{
          if(account.status==404){
            this.bad_username=true
            this.bad_passcode=false;
          }else{
            this.bad_passcode=true
            this.bad_username=false;
          }
        }
      },
      leave(){
        this.come=false;
        this.leaving=true;
        setTimeout(()=>{this.$router.push("/signup/")},1000)
      }
    },
    mounted(){
      setTimeout(()=>{this.come=true});
    }
  })
  export default class Login extends Vue {}
